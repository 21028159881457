import CurrencyDetails from "../types/CurrencyDetails";

const CURRENCIES: Record<string, CurrencyDetails> = {
  USD: { friendly: "US Dollar", symbol: "$" },
  GBP: { friendly: "British Pound Sterling", symbol: "£" },
  EUR: { friendly: "Euro", symbol: "€" },
  CAD: { friendly: "Canadian Dollar", symbol: "CA$" },
  INR: { friendly: "Indian Rupee", symbol: "₹" },
  UAH: { friendly: "Ukrainian Hryvnia", symbol: "₴" },
  AED: { friendly: "UAE Dh", symbol: "Dh" },
  AFN: { friendly: "Afghan Afghani", symbol: "Af" },
  ALL: { friendly: "Albanian Lek", symbol: "L" },
  AMD: { friendly: "Armenian Dram", symbol: "֏" },
  ANG: { friendly: "Guilder", symbol: "NAf" },
  AOA: { friendly: "Angolan Kwanza", symbol: "Kz" },
  ARS: { friendly: "Argentine Peso", symbol: "AR$" },
  AUD: { friendly: "Australian Dollar", symbol: "AU$" },
  AWG: { friendly: "Aruban Florin", symbol: "ƒ." },
  AZN: { friendly: "Azerbaijani Manat", symbol: "₼" },
  BAM: { friendly: "Bosnia-Herzegovina Mark", symbol: "KM" },
  BBD: { friendly: "Barbadian Dollar", symbol: "BB$" },
  BDT: { friendly: "Bangladeshi Taka", symbol: "৳" },
  BGN: { friendly: "Bulgarian Lev", symbol: "Лв." },
  BHD: { friendly: "Bahraini Dinar", symbol: "BD" },
  BIF: { friendly: "Burundian Franc", symbol: "FBu" },
  BMD: { friendly: "Bermudan Dollar", symbol: "BD$" },
  BND: { friendly: "Brunei Dollar", symbol: "BR$" },
  BOB: { friendly: "Bolivian Boliviano", symbol: "Bs" },
  BRL: { friendly: "Brazilian Real", symbol: "R$" },
  BSD: { friendly: "Bahamian Dollar", symbol: "BA$" },
  BTN: { friendly: "Bhutanese Ngultrum", symbol: "Nu." },
  BWP: { friendly: "Botswanan Pula", symbol: "P" },
  BYN: { friendly: "Belarusian Ruble", symbol: "Rbl" },
  BZD: { friendly: "Belize Dollar", symbol: "BZ$" },
  CDF: { friendly: "Congolese Franc", symbol: "FC" },
  CHF: { friendly: "Swiss Franc", symbol: "fr." },
  CLP: { friendly: "Chilean Peso", symbol: "CL$" },
  CNY: { friendly: "Chinese Yuan", symbol: "C¥" },
  COP: { friendly: "Colombian Peso", symbol: "CO$" },
  CRC: { friendly: "Costa Rican Colón", symbol: "₡" },
  CUP: { friendly: "Cuban Peso", symbol: "¢" },
  CVE: { friendly: "Cape Verdean Escudo", symbol: "CV$" },
  CZK: { friendly: "Czech Republic Koruna", symbol: "Kč" },
  DJF: { friendly: "Djiboutian Franc", symbol: "Fdj" },
  DKK: { friendly: "Danish Krone", symbol: "DK kr." },
  DOP: { friendly: "Dominican Peso", symbol: "RD$" },
  DZD: { friendly: "Algerian Dinar", symbol: "DA" },
  EGP: { friendly: "Egyptian Pound", symbol: "£E" },
  ERN: { friendly: "Eritrean Nakfa", symbol: "Nkf" },
  ETB: { friendly: "Ethiopian Birr", symbol: "Br" },
  FJD: { friendly: "Fijian Dollar", symbol: "FJ$" },
  FKP: { friendly: "Falkland Islands Pound", symbol: "FK$" },
  GEL: { friendly: "Georgian Lari", symbol: "GEL" },
  GGP: { friendly: "Guernsey Pound", symbol: "GU£" },
  GHS: { friendly: "Ghanaian Cedi", symbol: "GH₵" },
  GIP: { friendly: "Gibraltar Pound", symbol: "GI£" },
  GMD: { friendly: "Gambian Dalasi", symbol: "D" },
  GNF: { friendly: "Guinean Franc", symbol: "FG" },
  GTQ: { friendly: "Guatemalan Quetzal", symbol: "Q" },
  GYD: { friendly: "Guyanaese Dollar", symbol: "GY$" },
  HKD: { friendly: "Hong Kong Dollar", symbol: "HK$" },
  HNL: { friendly: "Honduran Lempira", symbol: "L" },
  HTG: { friendly: "Haitian Gourde", symbol: "G" },
  HUF: { friendly: "Hungarian Forint", symbol: "Ft" },
  IDR: { friendly: "Indonesian Rupiah", symbol: "Rp" },
  ILS: { friendly: "Israeli New Shekel", symbol: "₪" },
  IMP: { friendly: "Manx pound", symbol: "IM£" },
  IQD: { friendly: "Iraqi Dinar", symbol: "ID" },
  IRR: { friendly: "Iranian Rial", symbol: "RI" },
  ISK: { friendly: "Icelandic Króna", symbol: "IS kr." },
  JEP: { friendly: "Jersey Pound", symbol: "JE£" },
  JMD: { friendly: "Jamaican Dollar", symbol: "JM$" },
  JOD: { friendly: "Jordanian Dinar", symbol: "JD" },
  JPY: { friendly: "Japanese Yen", symbol: "J¥" },
  KES: { friendly: "Kenyan Shilling", symbol: "KSh" },
  KGS: { friendly: "Kyrgystani Som", symbol: "C_" },
  KHR: { friendly: "Cambodian Riel", symbol: "៛" },
  KMF: { friendly: "Comorian Franc", symbol: "FC" },
  KRW: { friendly: "Korean Won", symbol: "₩" },
  KWD: { friendly: "Kuwaiti Dinar", symbol: "KD" },
  KYD: { friendly: "Cayman Islands Dollar", symbol: "CI$" },
  KZT: { friendly: "Kazakhstani Tenge", symbol: "₸" },
  // LAK: { friendly: "Laotian Kip", symbol: "" },
  // LBP: { friendly: "Lebanese Pound", symbol: "" },
  // LKR: { friendly: "Sri Lankan Rupee", symbol: "" },
  // LRD: { friendly: "Liberian Dollar", symbol: "" },
  // LSL: { friendly: "Lesotho Loti", symbol: "" },
  // LYD: { friendly: "Libyan Dinar", symbol: "" },
  // MAD: { friendly: "Moroccan Dirham", symbol: "" },
  // MDL: { friendly: "Moldovan Leu", symbol: "" },
  // MGA: { friendly: "Malagasy Ariary", symbol: "" },
  // MKD: { friendly: "Macedonian Denar", symbol: "" },
  // MMK: { friendly: "Myanma Kyat", symbol: "" },
  // MNT: { friendly: "Mongolian Tugrik", symbol: "" },
  // MOP: { friendly: "Macanese Pataca", symbol: "" },
  // MRU: { friendly: "Mauritanian Ouguiya", symbol: "" },
  // MUR: { friendly: "Mauritian Rupee", symbol: "" },
  // MVR: { friendly: "Maldivian Rufiyaa", symbol: "" },
  // MWK: { friendly: "Malawian Kwacha", symbol: "" },
  MXN: { friendly: "Mexican Peso", symbol: "MX$" },
  // MYR: { friendly: "Malaysian Ringgit", symbol: "" },
  // MZN: { friendly: "Mozambican Metical", symbol: "" },
  // NAD: { friendly: "Namibian Dollar", symbol: "" },
  // NGN: { friendly: "Nigerian Naira", symbol: "" },
  // NIO: { friendly: "Nicaraguan Córdoba", symbol: "" },
  // NOK: { friendly: "Norwegian Krone", symbol: "" },
  // NPR: { friendly: "Nepalese Rupee", symbol: "" },
  NZD: { friendly: "New Zealand Dollar", symbol: "NZ$" },
  // OMR: { friendly: "Omani Rial", symbol: "" },
  // PAB: { friendly: "Panamanian Balboa", symbol: "" },
  // PEN: { friendly: "Peruvian Nuevo Sol", symbol: "" },
  // PGK: { friendly: "Papua New Guinean Kina", symbol: "" },
  // PHP: { friendly: "Philippine Peso", symbol: "" },
  // PKR: { friendly: "Pakistani Rupee", symbol: "" },
  // PLN: { friendly: "Polish Zloty", symbol: "" },
  // PYG: { friendly: "Paraguayan Guarani", symbol: "" },
  // QAR: { friendly: "Qatari Rial", symbol: "" },
  // RON: { friendly: "Romanian Leu", symbol: "" },
  // RSD: { friendly: "Serbian Dinar", symbol: "" },
  // RUB: { friendly: "Russian Ruble", symbol: "" },
  // RWF: { friendly: "Rwandan Franc", symbol: "" },
  // SAR: { friendly: "Saudi Riyal", symbol: "" },
  // SBD: { friendly: "Solomon Islands Dollar", symbol: "" },
  // SCR: { friendly: "Seychellois Rupee", symbol: "" },
  // SDG: { friendly: "Sudanese Pound", symbol: "" },
  SEK: { friendly: "Swedish Krona", symbol: "SE kr." },
  // SGD: { friendly: "Singapore Dollar", symbol: "" },
  // SHP: { friendly: "Saint Helena Pound", symbol: "" },
  // SLL: { friendly: "Sierra Leonean Leone", symbol: "" },
  // SOS: { friendly: "Somali Shilling", symbol: "" },
  // SRD: { friendly: "Surinamese Dollar", symbol: "" },
  // SSP: { friendly: "South Sudanese Pound", symbol: "" },
  // STN: { friendly: "São Tomé and Príncipe Dobra", symbol: "" },
  // SVC: { friendly: "Salvadoran Colón", symbol: "" },
  // SYP: { friendly: "Syrian Pound", symbol: "" },
  // SZL: { friendly: "Swazi Lilangeni", symbol: "" },
  // THB: { friendly: "Thai Baht", symbol: "" },
  // TJS: { friendly: "Tajikistani Somoni", symbol: "" },
  // TMT: { friendly: "Turkmenistani Manat", symbol: "" },
  // TND: { friendly: "Tunisian Dinar", symbol: "" },
  // TOP: { friendly: "Tongan Pa'anga", symbol: "" },
  // TRY: { friendly: "Turkish Lira", symbol: "" },
  // TTD: { friendly: "Trinidad and Tobago Dollar", symbol: "" },
  // TWD: { friendly: "New Taiwan Dollar", symbol: "" },
  // TZS: { friendly: "Tanzanian Shilling", symbol: "" },
  // UGX: { friendly: "Ugandan Shilling", symbol: "" },
  // UYU: { friendly: "Uruguayan Peso", symbol: "" },
  // UZS: { friendly: "Uzbekistan Som", symbol: "" },
  // VES: { friendly: "Venezuelan Bolívar Soberano", symbol: "" },
  // VND: { friendly: "Vietnamese Dong", symbol: "" },
  // VUV: { friendly: "Vanuatu Vatu", symbol: "" },
  // WST: { friendly: "Samoan Tala", symbol: "" },
  // ZAR: { friendly: "South African Rand", symbol: "" },
};

export default CURRENCIES;
